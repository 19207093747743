import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { environment } from '../../../../fms-web/src/environments/environment';
import { Observable } from 'rxjs';
import { Role } from '../models/role.model';
import { SearchModel } from '../models/search.model';
import { PaginatedObjectResult } from '../models/paginated-object-result.model';

@Injectable({
  providedIn: 'root',
})
export class RoleService extends DataService {
  constructor(public override http: HttpClient) {
    super(http, `${environment.usersApiUrlPart}/Roles`);
  }

  public getRolesWithFilter(
    pageIndex: number,
    itemsNumber: number,
    sortField: string,
    sortDirection: string = 'asc',
    filter?: any,
  ): Observable<PaginatedObjectResult<Role>> {
    return this.getPaginatedWithFilter<Role, SearchModel>(
      pageIndex,
      itemsNumber,
      sortField,
      sortDirection,
      filter,
    );
  }
}
